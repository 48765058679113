import http from "./index";

export const $getCourseList = params => {
	const data = http.post("xskc/Online_Courses/KcList", params);
	return data;
};

export const $getNavList = params => {
	const data = http.get("xskc/Online_Courses/KcLaberList", params);
	return data;
};

// 课时详情
export const $getCourseInfo = params => {
	const data = http.post("xskc/Oc_class/info", params, { needToken: true });
	return data;
};

// 课程报名
export const $enterCourse = params => {
	const data = http.post("xskc/Online_Courses/addClass", params, { needToken: true });
	return data;
};

// 课时用户更改收藏状态
export const $changeCollectStatus = params => {
	const data = http.post("xskc/Oc_class/upusersc", params, { needToken: true });
	return data;
};

// 课时评论内容列表
export const $getCommentList = params => {
	const data = http.post("xskc/Oc_class/ksContentList", params, { needToken: true });
	return data;
};

// 课时评论内容回复
export const $getCommentReplyList = params => {
	const data = http.post("xskc/Oc_class/kcchfpage", params, { needToken: true });
	return data;
};

// 课时内容点赞更改状态
export const $changeSupportStatus = params => {
	const data = http.post("xskc/Oc_class/kccdztype", params, { needToken: true });
	return data;
};

// 课时评论、维度评论
export const $submitComment = params => {
	const data = http.post("xskc/Oc_class/kscwdadd", params, { needToken: true, ContentType: "multipart/form-data" });
	return data;
};

// 回复评论
export const $replyUser = params => {
	const data = http.post("xskc/Oc_class/kscontentadd", params, { needToken: true });
	return data;
};

// 用户删除课时评论
export const $deleteComment = params => {
	const data = http.post("xskc/Oc_class/DelContent", params, { needToken: true });
	return data;
};

// 获取课程详情信息
export const $getClassInfo = params => {
	const data = http.post("xskc/Online_Courses/kcinfo", params, { needToken: true });
	return data;
};

// 获取课程资料列表
export const $getMaterialList = params => {
	const data = http.post("xskc/Online_Courses/kcziliao", params, { needToken: true });
	return data;
};

// 获取课程分组列表
export const $getGroupList = params => {
	const data = http.post("xskc/Online_Courses/fzlist", params, { needToken: true });
	return data;
};

// 课程收藏状态改变
export const $changeClassCollectStatus = params => {
	const data = http.post("xskc/Online_Courses/userkccs", params, { needToken: true });
	return data;
};

// 小组长拉人
export const $joinGroup = params => {
	const data = http.post("xskc/Online_Courses/laren", params, { needToken: true });
	return data;
};

// 客观题
export const $getObjectiveWork = params => {
	const data = http.post("xskc/Oc_class/timuiinfolist", params, { needToken: true });
	return data;
};
// 提交客观题
export const $submitObjectiveWork = params => {
	const data = http.post("xskc/Oc_class/timuadd", params, { needToken: true, ContentType: "multipart/form-data" });
	return data;
};

// 主观题
export const $getSubjectiveWork = params => {
	const data = http.post("xskc/Oc_class/wdtinfo", params, { needToken: true });
	return data;
};

// 提交主观题
export const $submitSubjectiveWork = params => {
	const data = http.post("xskc/Oc_class/wdtinfoadd", params, { needToken: true });
	return data;
};

export const $getCommentStatus = params => {
	const data = http.post("xskc/Oc_class/userwdpltype", params, { needToken: true });
	return data;
};

//
export const $uploadFile = params => {
	const data = http.post("Common/upload", params, { ContentType: "multipart/form-data" });
	return data;
};
