import Vue from "vue";
import Vuex from "vuex";
import createPersistedstate from "vuex-persistedstate";
import { $getUnreadMsgCount } from "../services/centerInfo.js";
Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		useInfo: {},
		unreadMsgCount: 0,
	},
	mutations: {
		UPDATEDSTATE(state, data) {
			const { key, value } = data;
			state[key] = value;
		},
	},
	actions: {
		async getUnreadMsgCount({ state, commit }) {
			const { data } = await $getUnreadMsgCount();
			if (data) {
				commit("UPDATEDSTATE", { key: "unreadMsgCount", value: data.count });
			}
		},
	},
	getters: {
		useInfo(state) {
			return state.useInfo;
		},
		unreadMsgCount(state) {
			return state.unreadMsgCount;
		},
	},
	plugins: [
		createPersistedstate({
			storage: window.localStorage,
			reducer(val) {
				return {
					useInfo: val.useInfo,
				};
			},
		}),
	],
	modules: {},
});
