<template>
	<div id="app">
		<vheader />
		<router-view />
		<vfooter />
	</div>
</template>
<script>
import vheader from "@/components/pageLayout/vheader.vue";
import vfooter from "@/components/pageLayout/vfooter.vue";
export default {
	components: {
		vheader,
		vfooter,
	},
	created() {
		this.$nextTick(() => {
			// 禁止右键
			document.oncontextmenu = new Function("event.returnValue=false");
			// 禁止F12
			document.onkeydown = () => {
				//禁用F12
				if (window.event && window.event.keyCode == 123) {
					return false;
					//禁用ctrl+shift+i,
				} else if (window.event.ctrlKey && window.event.shiftKey && window.event.keyCode == 73) {
					return false;
					//屏蔽Shift+F10
				} else if (window.event.shiftKey && window.event.keyCode == 121) {
					return false;
				}
			};
		});
	},
};
</script>
<style lang="less">
#app {
	font-size: 14px;
}
</style>
