import http from "./index";

export const $getMyInfo = params => {
	const data = http.request({
		url: "students/Students/xinxi",
		method: "get",
		params,
	});
	return data;
};

// 提交修改密码
export const $updatePassword = params => {
	const data = http.post("students/Students/updatepassword", params);
	return data;
};

export const $getMyCourse = params => {
	const data = http.post("xskc/Online_Courses/mykc", params);
	return data;
};

export const $getMyCollect = params => {
	const data = http.post("students/Students/mysclist", params);
	return data;
};

export const $cancelCollect = params => {
	const data = http.post("students/Students/myscquxiao", params);
	return data;
};
// 获取未读消息数量
export const $getUnreadMsgCount = params => {
	const data = http.request({
		url: "message/station_letter/znxcount",
		method: "get",
		params,
	});
	return data;
};
// 站内信列表
export const $getMsgList = params => {
	const data = http.request({
		url: "message/station_letter/znxlist",
		method: "get",
		params,
	});
	return data;
};

// 修改为已读
export const $sendMsgRead = params => {
	const data = http.post("message/station_letter/setmessage", params);
	return data;
};
