<!--  -->
<template>
	<div class="com-vfooter">
		<div class="footer-container">
			<div class="content flex-jc-ac flex-col">
				<!-- <div class="footer-nav flex-jc-ac">
					<div class="nav">联系我们</div>
					<div class="nav">公司介绍</div>
					<div class="nav">合作部门</div>
				</div> -->
				<p><a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">ICP备案号：沪ICP备2023027728号</a></p>
				<p>Copyright © 2023 Shengrui Consulting. All Rights Reserved</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "vfooter",
	data() {
		return {};
	},
	components: {},

	computed: {},

	created() {},

	methods: {},
};
</script>

<style scoped lang="less">
.com-vfooter {
	height: 150px;
	width: 100%;
	.footer-container {
    height: 150px;
		background-color: #fff;
		.content {
      height: 150px;
			font-size: 14px;
			color: #545c63;
      line-height: 24px;
      .footer-nav {
        margin-bottom: 20px;
        .nav {
          margin: 0 40px;
        }
      }
		}
	}
}
</style>
