import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "Home",
		component: Home,
		meta: {
			needLogin: false,
		},
	},
	{
		path: "/openClass",
		name: "OpenClass",
		component: () => import("../views/openClass.vue"),
	},
	{
		path: "/company",
		name: "Company",
		component: () => import("../views/company.vue"),
	},
	{
		path: "/openClassInfo",
		name: "OpenClassInfo",
		component: () => import("../views/openClassInfo.vue"),
	},
	{
		path: "/login",
		name: "Login",
		component: () => import("../views/login.vue"),
	},
	{
		path: "/resetPassword",
		name: "ResetPassword",
		component: () => import("../views/resetPassword.vue"),
	},
	{
		path: "/courseDetail",
		name: "CourseDetail",
		component: () => import("../views/courseDetail.vue"),
	},
	{
		path: "/study",
		name: "Study",
		component: () => import("../views/study.vue"),
		meta: {
			needLogin: true,
		},
	},
	{
		path: "/subjectiveWork",
		name: "SubjectiveWork",
		component: () => import("../views/subjectiveWork.vue"),
		meta: {
			needLogin: true,
		},
	},
	{
		path: "/objectiveWork",
		name: "ObjectiveWork",
		component: () => import("../views/objectiveWork.vue"),
		meta: {
			needLogin: true,
		},
	},
	{
		path: "/message",
		name: "Message",
		component: () => import("../views/message.vue"),
		meta: {
			needLogin: true,
		},
	},
	{
		path: "/myCenter",
		component: () => import("../views/myCenter.vue"),
		meta: {
			needLogin: true,
		},
		children: [
			{
				path: "",
				alias: "course",
				component: () => import("../components/center/myCourse.vue"),
			},
			{
				path: "collect",
				component: () => import("../components/center/myCollect.vue"),
			},
			{
				path: "myInfo",
				component: () => import("../components/center/myInfo.vue"),
			},
			{
				path: "changePassword",
				component: () => import("../components/center/changePassword.vue"),
			},
		],
	},
];

const router = new VueRouter({
	routes,
	scrollBehavior: () => ({ y: 0 }),
});

router.beforeEach((to, from, next) => {
	if (to.meta && to.meta.title) {
		document.title = to.meta.title;
	}
	const token = localStorage.getItem("token");
	if (to.meta && to.meta.needLogin && !token) {
		next({ name: "Login" });
		return;
	}
	next();
});

export default router;
