import axios from "axios";
import Vue from "vue";
import Store from "../store";
import config from '@/utils/env_config'
import Router from "../router";

let instance = axios.create({
	timeout: 10000,
	// baseURL: "http://kctest.meetv.com.cn/api/",
	baseURL: config.baseURL,
	headers: {
		"Content-Type": "application/json;charset=utf-8",
	},
});

instance.interceptors.request.use(
	// 在发送前做点什么
	function (config) {
		if (config.ContentType) {
			config.headers["Content-Type"] = config.ContentType;
		} else {
			config.headers["Content-Type"] = "application/json;charset=utf-8";
		}
		if (Store.getters.useInfo && Store.getters.useInfo._token) {
			config.headers.TOKEN = Store.getters.useInfo._token;
			if (config.data) {
				config.data.sid = Store.getters.useInfo.id + "";
			}
		}
		return config;
	},
	// 对请求错误做点什么
	function (error) {
		return Promise.reject(error);
	}
);

instance.interceptors.response.use(
	// 对响应数据做点什么
	function (config) {
		if (!config || !config.data) {
			Vue.prototype.$message.error("数据错误");
			return Promise.reject(config);
		}
		if (config.data.code === -1) {
			Router.push({path: '/login'})
			return Promise.reject(config.data);
		} else if (config.data.code === 0) {
			Vue.prototype.$alert(config.data.msg, "", {
				confirmButtonText: "确定",
				center: true,
			});
			return Promise.reject(config.data);
		}
		return config.data;
	},
	// 对响应错误做点什么
	function (error) {
		if (error.response.status === 401) {
			Vue.prototype.$message.error("您还没有访问权限");
		} else {
			Vue.prototype.$message.error("网络错误");
		}
		return Promise.reject(error);
	}
);

export default instance;
