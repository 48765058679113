<!-- 页面头部 -->
<template>
	<div class="com-vheader">
		<div class="content flex-jb-ac">
			<div class="left flex-ac">
				<img @click="goHome" :src="logo" alt="" class="logo" />
				<div @click="goHome" :style="{color: activeNav == 1 ? '#15abee' : ''}" class="nav">所有课程</div>
				<!-- <div @click="goOpenClass" :style="{color: activeNav == 2 ? '#15abee' : ''}" class="nav">公开课</div> -->
				<!-- <div @click="goCompany" :style="{color: activeNav == 3 ? '#15abee' : ''}" class="nav">公司简介</div> -->
			</div>
			<div class="right flex-ac" v-if="hasLogin">
				<div class="message" @click="goToMessagePage">
					<div class="title">消息</div>
					<div class="mark" v-if="unreadMsgCount > 0">{{ unreadMsgCount }}</div>
				</div>
				<div class="user-center" @click="goToMyCenterPage">
					<div class="title">个人中心</div>
				</div>
			</div>
			<div class="right" v-else>
				<div class="login" @click="toLogin">登录</div>
			</div>
		</div>
	</div>
</template>

<script>
import { $getConfig } from "../../services/common";
export default {
	name: "vheader",
	data() {
		return {
			activeNav: 0,
			logo: ''
		};
	},
	components: {},

	
	// 监听,当路由发生变化的时候执行
	watch:{
		$route(to,from){

			switch (to.path){
				case '/':
					this.activeNav = 1
					break;
				case '/openClass':
					this.activeNav = 2
					break;
				case '/company':
					this.activeNav = 3
					break;
				default :
					this.activeNav = 0
			}
		}
	},

	computed: {
		hasLogin() {
			if (this.$store.getters.useInfo && this.$store.getters.useInfo._token) {
				return true;
			} else {
				this.$store.commit("UPDATEDSTATE", { key: "useInfo", value: {} });
				localStorage.clear();
				return false;
			}
		},
		unreadMsgCount() {
			return this.$store.getters.unreadMsgCount;
		},
	},

	created() {
		this.getConfig()
		if (this.$store.getters.useInfo && this.$store.getters.useInfo._token) {
			this.$store.dispatch("getUnreadMsgCount");
		}
	},

	mounted() {},

	methods: {
		async getConfig() {
			const { data } = await $getConfig();
			this.logo = data.qylogoimg || ''
		},
		goHome() {
			if (this.$route.name === "Home") return;
			this.$router.push({ path: "/" });
		},
		toLogin() {
			if (this.$route.name === "Login") return;
			this.$router.push({ path: "/login" });
		},
		goToMessagePage() {
			if (this.$route.name === "Message") return;
			this.$router.push({ path: "/message" });
		},
		goToMyCenterPage() {
			if (this.$route.name === "MyCenter") return;
			this.$router.push({ path: "/myCenter" });
			// this.$router.push({ path: "/myCenter/myInfo" });
		},
		goOpenClass() {
			if (this.$route.name === "OpenClass") return;
			this.$router.push({ path: "/openClass" });
		},
		goCompany() {
			if (this.$route.name === "Company") return;
			this.$router.push({ path: "/company" });
		}
	},
};
</script>

<style scoped lang="less">
.com-vheader {
	width: 100%;
	.content {
		width: 1200px;
		height: 82px;
		margin: 0 auto;
		.left {
			.logo {
				width: 166px;
				height: 42px;
				margin-right: 50px;
				cursor: pointer;
			}
			.nav {
				font-size: 18px;
				margin: 0 40px;
				color: #1c1f21;
				cursor: pointer;
				&:hover {
					color: #15abee;
				}
			}
		}
		.right {
			font-size: 12px;
			color: #333c45;
			.message,
			.user-center {
				padding-left: 20px;
				margin-left: 40px;
				cursor: pointer;
			}
			.login {
				font-size: 16px;
				cursor: pointer;
				user-select: none;
				&:hover {
					color: #15abee;
				}
			}
			.message {
				position: relative;
				background-image: url("../../assets/image/icon-mgs1.png");
				background-repeat: no-repeat;
				background-size: 15px 14px;
				background-position-y: center;
				&:hover {
					color: #15abee;
					background-image: url("../../assets/image/icon-mgs2.png");
				}
				.mark {
					position: absolute;
					right: -16px;
					top: -10px;
					min-width: 18px;
					text-align: center;
					line-height: 18px;
					height: 18px;
					background: #f54747;
					border-radius: 9px;
					color: #fff;
					font-size: 12px;
				}
			}
			.user-center {
				background-image: url("../../assets/image/icon-user1.png");
				background-repeat: no-repeat;
				background-size: 15px 15px;
				background-position-y: center;
				&:hover {
					color: #15abee;
					background-image: url("../../assets/image/icon-user2.png");
				}
			}
		}
	}
}
</style>
