let config = {
	baseURL: "//jiaoxueyanping.lijing-group.com/api/",
  webSocketUrl: 'ws://kctest.meetv.com.cn:9292',
};

if (process.env.NODE_ENV === "production") {
	config.baseURL = "http://shengrmaster.meetv.com.cn/api/";
	config.webSocketUrl = "ws://shengrmaster.meetv.com.cn:8285";
} else if (process.env.NODE_ENV === "preparation") {
	config.baseURL = "http://shengrmaster.meetv.com.cn/api/";
	config.webSocketUrl = "ws://shengrmaster.meetv.com.cn:8285";
} else if (process.env.NODE_ENV === "development") {
	config.baseURL = "http://testlj.meetv.com.cn//api/";
	config.webSocketUrl = "ws://shengrmaster.meetv.com.cn:8285";
}

export default config