import Vue from "vue";

// element
import "element-ui/lib/theme-chalk/index.css";
import { Button, Input, Form, FormItem, Icon, Message, MessageBox, Pagination, Rate } from "element-ui";

Vue.use(Button);
Vue.use(Input);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Icon);
Vue.use(Pagination);
Vue.use(Rate);
Vue.prototype.$message = Message;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
