import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./utils/plugins_config";
import "./assets/css/common.css";
import "./assets/css/reset.css";

import './utils/flexible'

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	render: h => h(App),
}).$mount("#app");
